/* eslint-disable no-console */
import {
  config
} from "@/_config";
import {
  handleResponse,
  authHeader
} from "@/_helpers";

// import axios from "axios";

export const recordsService = {
  recordAll,
  recordById,
  recordCreate,
  recordUpdate,
  recordFileUpdate,
  recordClone,
  recordDelete,
  zipCodesSearch,
  zipCodesAll,
  provCCAAsAll,
  recordAddressNew,
  recordAddressUpdate,
  recordAddressDelete,
  recordAddressSetMain,
  recordNotiNew,
  recordNotiUpdate,
  recordNotiDelete,
  recordSpecNew,
  recordSpecUpdate,
  recordSpecDelete,
  statusesAll,
  downloadAttachment,
  deleteAttachment,
  delRecordSpecialties,
  crearComMultiple,
  delGrupoComentarios
};

/* función para recuperar todas las fichas */
function recordAll() {
  const url = `${config.apiUrl}/records`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  // console.log(miConfig);

  return axios
    .get(url, miConfig)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      // eslint-disable-next-line no-console
      console.log(err.response);
      if (err.response.statusText === "Unauthorized") {
        return -1;
      }
      return err;
    });
}

/* función para recuperar una ficha por su id */
function recordById(id) {
  const url = `${config.apiUrl}/records/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
}

/* función para crear una ficha */
function recordCreate(data) {
  const url = `${config.apiUrl}/records`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .post(url, data, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    });
}

/* función para actualizar una ficha */
function recordUpdate(datos) {
  const url = `${config.apiUrl}/records/update`;
  let data = {
    ...datos
  };
  /*  datos: {
    reco_name: null,
    reco_phone: null,
    reco_cif: null,
    reco_email: null,
    reco_address: null,
    reco_idstatus: null,
    reco_idcp: null,
    reco_idticen: null,
    reco_agentname: null,
    reco_agentdni: null,
    reco_archivo: null,
    reco_creationdate: null,
    reco_contractdate: null,
    reco_notificaciondate: null,
    record_specialties: [],
    record_notifications: [],
    record_zip_code: null,
    record_addresses: []
  } */

  delete data.record_specialties;
  delete data.record_notifications;
  delete data.record_zip_code;
  delete data.record_files;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .put(url, data, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

function recordFileUpdate(id, datos) {
  const url = `${config.apiUrl}/recordFile/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
      .put(url, datos, miConfig)
      .then(res => {
        return res.data;
      })
      .catch(err => {
        return err;
      });
}

/* para clonar una ficha existente */
function recordClone(id) {
  const url = `${config.apiUrl}/recordClone`;
  const params = new URLSearchParams();
  params.append("record_id", id);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };
  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

/*  funcion para eliminar una ficha OJO: softDeletes */
function recordDelete(id) {
  const url = `${config.apiUrl}/records/${id}`;
  const params = new URLSearchParams();
  params.append("record_id", id);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };
  return axios
    .delete(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error);
      return Promise.reject(error);
    });
}

/* función de filtrado de dirección */
function zipCodesSearch(filtro) {
  const url = `${config.apiUrl}/zipCodesSearch`;
  const params = new URLSearchParams();
  params.append("filtro", filtro);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };
  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
    });
}

/* funcion para recoger todos los zipcodes */
function zipCodesAll() {
  const url = `${config.apiUrl}/zipCodesAll`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

/* funcion para recoger todos los provincias CCAA */
function provCCAAsAll() {
  const url = `${config.apiUrl}/provsCCAA`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      return error;
    });
}

/* funcion para crear una dirección nueva */
function recordAddressNew(datos) {
  const url = `${config.apiUrl}/recordAddress`;
  const params = new URLSearchParams();
  params.append("read_address", datos.read_address);
  params.append("read_phone", datos.read_phone);
  params.append("read_idcp", datos.read_idcp);
  if (datos.read_phonecta) params.append("read_phonecta", datos.read_phonecta);
  if (datos.read_web) params.append("read_web", datos.read_web);
  if (datos.read_descuento) params.append("read_descuento", datos.read_descuento);
  params.append("reco_id", datos.reco_id);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion para editar una dirección */
function recordAddressUpdate(datos) {
  const url = `${config.apiUrl}/recordAddress/update`;
  const params = new URLSearchParams();
  params.append("read_address", datos.read_address);
  params.append("read_phone", datos.read_phone);
  params.append("read_idcp", datos.read_idcp);
  if (datos.read_phonecta) params.append("read_phonecta", datos.read_phonecta);
  if (datos.read_web) params.append("read_web", datos.read_web);
  if (datos.read_descuento) params.append("read_descuento", datos.read_descuento);
  params.append("read_id", datos.read_id);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .put(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion parar eliminar una dirección existente */
function recordAddressDelete(id) {
  const url = `${config.apiUrl}/recordAddress/${id}`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
/* función para marcar una dirección como principal - sin uso actualmente*/
function recordAddressSetMain(id) {
  const url = `${config.apiUrl}/recordAddress/main`;
  const params = new URLSearchParams();
  params.append("read_id", id);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion para crear aviso nuevo */
function recordNotiNew(datos) {
  //  console.log(datos);
  const url = `${config.apiUrl}/recordNoti`;
  const params = new URLSearchParams();
  params.append("reno_idrecord", datos.reno_idrecord);
  params.append("reno_notificationdate", datos.reno_notificationdate);
  params.append("reno_comments", datos.reno_comments);
  params.append("reno_user", datos.reno_user);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion para editar un aviso */
function recordNotiUpdate(datos) {
  const url = `${config.apiUrl}/recordNoti/update`;
  const params = new URLSearchParams();
  params.append("reno_id", datos.reno_id);
  params.append("reno_notificationdate", datos.reno_notificationdate);
  params.append("reno_comments", datos.reno_comments);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .put(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion parar eliminar un aviso existente */
function recordNotiDelete(id) {
  const url = `${config.apiUrl}/recordNoti/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion para crear especialidad nuevo */
function recordSpecNew(datos) {
  // console.log(datos);
  const url = `${config.apiUrl}/recordSpec`;
  const params = new URLSearchParams();
  params.append("resp_idrecord", datos.resp_idrecord);
  params.append("resp_idcategory", datos.resp_idcategory);
  params.append("resp_idsubcategory", datos.resp_idsubcategory);
  params.append("resp_comments", datos.resp_comments);

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .post(url, params, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion para editar una especialidad */
function recordSpecUpdate(datos) {
  const url = `${config.apiUrl}/recordSpec/update`;
  /*   const params = new URLSearchParams();
  params.append("resp_id", datos.resp_id);
  params.append("resp_idcategory", datos.resp_idcategory);
  params.append("resp_idsubcategory", datos.resp_idsubcategory);
  params.append("resp_comments", datos.resp_comments); */

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .put(url, datos, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

/* funcion parar eliminar una especialidad existente */
function recordSpecDelete(id) {
  const url = `${config.apiUrl}/recordSpec/${id}`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
/* funcion para recuperar todos los datos de una ficha */
function statusesAll(id) {
  const url = `${config.apiUrl}/estados`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .get(url, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
}

function downloadAttachment(id) {
  const url = `${config.apiUrl}/getAttachment/${id}`;
  const headers = {
    responseType: "blob",
    headers: {
      Accept: "application/pdf",
      ...authHeader()
    }
  };
  return axios
    .get(url, headers)
    .then(res => {
      return res;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
}

function deleteAttachment(id) {
  // console.log(id);

  const url = `${config.apiUrl}/deleteAttachment/${id}`;
  const miConfig = {
    headers: {
      /* "Content-Type": "application/x-www-form-urlencoded", */
      Accept: "application/pdf",
      ...authHeader()
    }
  };

  return axios
    .delete(url, miConfig)
    .then(res => {
      return res;
    })
    .catch(function (error) {
      // console.log(error);
      return error;
    });
}

/* funcion para eliminar una escialidad y sus comentarios de una ficha */
/* función para crear una ficha */
function delRecordSpecialties(data) {
  /*
   $resp_idrecord = $request->id_record;
        $resp_idcategory = $request->id_category;
         */
  const url = `${config.apiUrl}/delRecordSpecialties`;
  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };
  return axios
    .post(url, data, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.log(error);
    });
}

function crearComMultiple_old(datos) {
  const url = `${config.apiUrl}/crearComMultiple`;

  const miConfig = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      ...authHeader()
    }
  };

  return axios
    .post(url, datos, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

function crearComMultiple(datos) {
  const url = `${config.apiUrl}/crearComMultiple`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .post(url, datos, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}

function delGrupoComentarios(datos) {
  const url = `${config.apiUrl}/delGrupoComentarios`;

  const miConfig = {
    headers: {
      "Content-Type": "application/json",
      ...authHeader()
    }
  };

  return axios
    .post(url, datos, miConfig)
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      console.log(error);
      return error;
    });
}
